<template>
    <v-container
        id="neworder"
        fluid
        tag="section"
        class="full-height"
    >
    <!-- {{ networks }} -->
    <!-- {{ invoices }} -->
        <v-row>
            <v-col
                cols="12"
            >
                <base-material-card
                    icon="mdi-receipt"
                    :title="invoices ? 'You invoices:' : 'Your invoice bank is empty'"
                >
                    <template
                        v-if="!invoices"
                    >
                        <v-row
                            style="height: 50vh;"
                            align="center"
                            justify="center"
                        >
                            <v-col
                                cols="12"
                                class="text-center mb-5"
                            >
                                YOUR INVOICES WILL APPEAR HERE AFTER YOU SUBSCRIBE
                            </v-col>
                        </v-row>
                    </template>
                    <template
                        v-else
                    >
                        <v-row
                            class="mt-4 mb-4"
                            align="center"
                            justify="center"
                            v-for="(invoice, index) in invoices"
                            :key="index"
                        >
                            <v-col
                                cols="12"
                                md="6"
                                class="text-center"
                            >
                                
                                <a :href="invoice.invoice_url" target="_blank">
                                    <div
                                        class="invoice"
                                    >
                                        {{ invoice.date }} - {{ invoice.name }}
                                    </div>
                                </a>
                            </v-col>
                        </v-row>
                    </template>
                </base-material-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { mapGetters } from "vuex"

export default {
    name: 'Invoices',
    props: ['fetchedOrLiveUser', 'liveFeed'],
    data() {
        return {

        }
    },
    methods: {
        dateConverter(dateStr) {
            const a = new Date(dateStr);
            const months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
            const year = a.getFullYear();
            const month = months[a.getMonth()];
            const date = a.getDate();
            const hour = a.getHours();
            const min = a.getMinutes();
            const time = date + ' ' + month + ' ' + year + ' ' + hour + ':' + min
            return time;
        },
        networkname(netId, quantity) {
            if (!this.networks) {
                return 'loading'
            }
            const filteredNet = this.networks.filter( net => {
                return net.net_id === netId
            })
            if (quantity === "20" && netId === "0") {
                return filteredNet[0].name
            }
            return filteredNet[1].name
        }
    },
    computed: {
        
        ...mapGetters(["networks"]),

        invoices() {
            if (!this.fetchedOrLiveUser) {
                return null
            }
            if (this.fetchedOrLiveUser.lists.length < 1) {
                return null
            }
            const invoiceArray = []
            this.fetchedOrLiveUser.lists.forEach(invoice => {
                const nextInvoice = {
                   date: this.dateConverter(invoice.order_date),
                   name: this.networkname(invoice.network_id, invoice.order_quantity) + ' x' + invoice.order_quantity,
                   invoice_url: invoice.invoice_url
                }
                invoiceArray.push(nextInvoice)
            });
            return invoiceArray
        },

    }
}
</script>

<style scoped>
.full-height {
    min-height: 87vh;
}
.invoice {
        text-align: center;
        background-color: #e91e63;
        color: white;
        border-radius: 10px;
        padding-top: 5px;
        padding-bottom: 5px;
        cursor: pointer;
    }
</style>